<template>
  <div id="app">
    <notifications />
    <router-view>
    </router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>
